import React, { useEffect } from "react";
import Swal from "sweetalert2";
import history from "../../../components/History";

export const Error404 = () => {
  useEffect(() => {
    Swal.fire({
      title: "Error 404!",
      text: "Page not found.",
      confirmButtonText: "Ok",
      icon: "error",
    }).then((result) => {
      history.push("/");
    });
  }, []);
  return <></>;
};
